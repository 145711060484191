/* eslint-disable prefer-template */
if (document.documentMode) {
    document.addEventListener('readystatechange', replaceBodyWithNotice);
}

function replaceBodyWithNotice() {
    document.body.innerHTML = '<div class="home__content" align="center">' +
        '<div class="logo">' +
        '<a href="/" class="logo__link">' +
        '<div class="logo__image iframe-logo__image" style="min-width: 118.37px;">' +
        '<img src="' + window.logo + '" title="' + window.pharmacyName + '" alt="' + window.pharmacyName + '">' +
        '</div>' +
        '<div class="logo__title">' +
        '<span class="logo__title iframe-logo__title custom-color-f">' + window.pharmacyName + '</span>' +
        '</div>' +
        '</a>' +
        '</div>' +
        '<p class="margin-top-m">Willkommen bei ihrer ' + window.pharmacyName + '.</p>' +
        '<p>Sie verwenden den Internet Explorer, welcher ab dem 15.06.2022 nicht mehr von Microsoft unterstützt wird.</p>' +
        '<p>Wir reagieren bereits jetzt und bitten sie einen von uns unterstützten Browser zu benutzen:</p>' +
        '<ul>' +
        '<li><a href="https://www.mozilla.org/de/firefox/new/">Mozilla Firefox</a></li>' +
        '<li><a href="https://www.google.com/chrome/">Google Chrome</a></li>' +
        '<li><a href="https://www.microsoft.com/de-de/edge">Microsoft Edge</a></li>' +
        '</ul>' +
        '<p class="margin-top-m">Ihre Apotheke.</p>' +
        '</div>';

    document.removeEventListener('readystatechange', replaceBodyWithNotice);
}